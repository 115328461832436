<template>
    <div class="section_right">
        <!--section_right-->
        <div class="sub_top">
            <!--sub_top-->
            <div class="sub_title">
				<i class="far fa-comments-alt text-primary"></i>{{$t('inquiry.1')}}</div>
        </div>
        <!--sub_top end-->
        <div class="section_right_inner">
            <!--section_right_inner-->
            <div class="notice_table02">
                <div class="mb-1 ">
                    <p class="input_title">{{$t("inquiry.8")}}</p>
                    <input type="text" class="index_input01" name="c_username"  :placeholder="`${$t('inquiry.9')}`" v-model="title" autocomplete='off'>
                </div>
                <div class="">
                    <p class="input_title">{{$t("inquiry.10")}}</p>
                    <textarea type="text" name="c_username"  :placeholder="`${$t('inquiry.11')}`" v-model="memo" autocomplete='off' class="index_textarea01" style="width:100%;border-radius: 5px;padding :10px; border-color: #ddd" rows="5"></textarea>
                </div>
                <div class="d-flex mt-3">
                    <button class="btn listgo_btn" @click="AddInquiry()" style="margin-right: 10px !important;">{{ $t('inquiry.7') }}</button>
                    <button class="btn listgo_btn" @click="$router.push({path:'/'+$i18n.locale+'/inquiry'})" >
                        {{ $t('inquiry.6') }}
                    </button>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
    data(){
        return{
            code : this.$route.params.code,
            title: '', 
            memo: ''
        }
    },
    mounted (){
        
    },
    methods : {
        AddInquiry(){
			const title = this.title;
            const memo = this.memo;
			const body = {title,memo};

            if(title ==''){
                this.$alert(this.$t('inquiry.9'))
                return false;
            }

            if(memo ==''){
                this.$alert(this.$t('inquiry.11'))
                return false;
            }
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/board/AddInquiry`,{req}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$t('inquiry.12')).then(
                                ()=>{
                                    this.$router.push({path:'/'+this.$i18n.locale+'/inquiry'})
                                }
                            )
                        }
                    }

                }
            ).catch(() => {});
        }
    }
}
</script>

<style>

</style>