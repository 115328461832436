<template>
  <div class="home" id="wrap">
    <Header />
    <Sidebar />
      <Main />
      <Navbar />
  </div>
</template>

<script>
import Header from '@/components/common/Header'
import Sidebar from '@/components/common/Sidebar'
import Main from '@/components/board/InquiryCreate'
import Navbar from '@/components/common/Navbar.vue'
export default {
  name: 'Home',
  components: {
    Header,
    Sidebar,
    Main,
    Navbar
  }
}
</script>
